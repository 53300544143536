import React, { useState } from "react";
import {
  Button,
  Container,
  Hidden,
  Toolbar,
  AppBar,
  Box,
  IconButton,
  Zoom,
  Fab,
  useScrollTrigger,
  Typography,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "./components/Drawer";
import About from "./components/About";
import Banner from "./components/Banner";
import Technologies from "./components/Technologies";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import AutoM from "./components/AutoM";

const drawerWidth = 240;

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>, id: any) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector(id);

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    if (mobileOpen) {
      setTimeout(() => {
        handleDrawerToggle();
      }, 900);
    }
  };

  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          alignItems: { xs: "unset", lg: "center" },
          width: { xs: "100vw" },
        }}
      >
        <Toolbar
          sx={{
            justifyContent: { xs: "unset", lg: "space-between" },
            backgroundColor: "white",
            width: { xs: "100vw", lg: 1200 },
          }}
        >
          <Hidden lgUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                position: "absolute",
                marginLeft: "1px",
              }}
            >
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
            <img
              src="./solidsoftware-blue.png"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                maxWidth: "150px",
                margin: "auto",
                cursor: "pointer",
              }}
              onClick={(event: any) => {
                handleClick(event, "#back-to-top-anchor");
              }}
            />
          </Hidden>
          <Hidden lgDown>
            <img
              src="./solidsoftware-blue.png"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                maxWidth: "200px",
                cursor: "pointer",
              }}
              onClick={(event: any) => {
                handleClick(event, "#back-to-top-anchor");
              }}
            />
            <div>
              <Button
                color="inherit"
                sx={{ marginLeft: 2, color: "black" }}
                onClick={(event: any) => handleClick(event, "#about-container")}
              >
                Sobre Nós
              </Button>
              <Button
                color="inherit"
                sx={{ marginLeft: 2, color: "black" }}
                onClick={(event: any) =>
                  handleClick(event, "#technologies-container")
                }
              >
                Tecnologias
              </Button>
              <Button
                color="inherit"
                sx={{ marginLeft: 2, color: "black" }}
                onClick={(event: any) =>
                  handleClick(event, "#products-container")
                }
              >
                Nossos Produtos
              </Button>
              <Button
                color="inherit"
                sx={{ marginLeft: 2, color: "black" }}
                onClick={(event: any) =>
                  handleClick(event, "#contact-container")
                }
              >
                Fale Conosco
              </Button>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>

      <Drawer
        drawerWidth={drawerWidth}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        handleClick={handleClick}
      />

      <Box component="main">
        <Toolbar id="back-to-top-anchor" />
        <Banner />
        <About />
        <Technologies />
        <AutoM />
        <Contact />
        <Footer />
        <ScrollToTop>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollToTop>
      </Box>
    </div>
  );
}

export default App;
