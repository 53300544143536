import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ImageList,
  ImageListItem,
  useMediaQuery,
  IconButton,
  Hidden,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { Phone, Email } from "@mui/icons-material";
import React from "react";
import Form from "../Form";

// import { Container } from './styles';

const Contact: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      id="contact-container"
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingTop: { xs: 9, md: 10 },
        paddingBottom: { xs: 9, md: 10 },
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            marginBottom: { xs: 4, md: 0 },
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", marginBottom: 8 }}
          >
            <Typography
              sx={{
                fontSize: { xs: 45, md: 65 },
                color: "white",
                marginRight: { md: 2 },
                textAlign: { xs: "center", md: "start" },
              }}
            >
              Vamos
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 45, md: 65 },
                fontWeight: "bold",
                marginTop: -3,
                color: "white",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              Conversar
            </Typography>
          </Box>
          <Typography
            sx={{
              textAlign: { xs: "center", md: "start" },
              fontSize: { xs: 18, md: 18 },
              color: "white",
            }}
          >
            Envie sua mensagem através do
          </Typography>
          <Typography
            sx={{
              textAlign: { xs: "center", md: "start" },
              fontSize: { xs: 18, md: 18 },
              color: "white",
            }}
          >
            formulário ou entre em contato:
          </Typography>
          <Box sx={{ marginTop: { xs: 5, md: 8 }, marginRight: { md: 5 } }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", md: "start" },
                marginBottom: 1,
              }}
            >
              <Hidden mdDown>
                {/* <Phone sx={{ width: 35, height: 35, color: "white" }} /> */}
                <Typography
                  sx={{
                    textAlign: {
                      xs: "center",
                      md: "start",
                      color: "white",
                      marginBottom: "8px",
                    },
                  }}
                >
                  
                </Typography>
              </Hidden>

              <Hidden mdUp>
                <Box>
                  <Typography
                    sx={{
                      textAlign: {
                        xs: "center",
                        md: "start",
                        color: "white",
                        marginBottom: "8px",
                      },
                    }}
                  >
                    
                  </Typography>
                </Box>
              </Hidden>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", md: "start" },
              }}
            >
              {/* <Email
                sx={{ width: 35, height: 35, marginRight: 2, color: "white" }}
              /> */}
              <Typography
                sx={{
                  textAlign: { xs: "center", md: "start", color: "white" },
                }}
              >
                contato@solidsoftware.tech
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Form />
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;
