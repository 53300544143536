import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { styled, alpha } from "@mui/material/styles";

// import { Container } from './styles';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  textAlign: "center",
  color: "white",
  display: "flex",
  justifyContent: "center",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: "30px",
}));

const Technologies: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      id="technologies-container"
      sx={{
        paddingTop: { xs: 5, md: 10 },
        paddingBottom: { xs: 5, md: 15 },
        backgroundImage: "url(./tech.png)",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 45, md: 65 },
              color: "white",
            }}
          >
            Principais
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 45, md: 65 },
              fontWeight: "bold",
              marginTop: -3,
              color: "white",
            }}
          >
            Tecnologias
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
          }}
        >
          <StyledBox sx={{ textAlign: "center" }}>
            <Typography
              sx={{ color: "white", fontSize: 18, fontWeight: "bold" }}
            >
              Design/Prototipação
            </Typography>
            <List>
              <StyledListItem>
                <img src="figma_ic.png" width={52} />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary="Figma" />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary="Invision" />
              </StyledListItem>
            </List>
          </StyledBox>
          <StyledBox sx={{ textAlign: "center" }}>
            <Typography
              sx={{ color: "white", fontSize: 18, fontWeight: "bold" }}
            >
              Back-end
            </Typography>
            <List>
              <StyledListItem>
                <img src="csharp_ic.png" width={38} />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary="C#" />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary="NodeJS" />
              </StyledListItem>
            </List>
          </StyledBox>
          <StyledBox sx={{ textAlign: "center" }}>
            <Typography
              sx={{ color: "white", fontSize: 18, fontWeight: "bold" }}
            >
              Front-end
            </Typography>
            <List>
              <StyledListItem>
                <img src="react_ic.png" width={52} />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary="React" />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary="VueJS" />
              </StyledListItem>
            </List>
          </StyledBox>
          <StyledBox sx={{ textAlign: "center" }}>
            <Typography
              sx={{ color: "white", fontSize: 18, fontWeight: "bold" }}
            >
              Aplicativos Móveis
            </Typography>
            <List>
              <StyledListItem>
                <img src="react_ic.png" width={52} />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary="React Native" />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary="Flutter" />
              </StyledListItem>
            </List>
          </StyledBox>
          {/* <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography
              sx={{ color: "white", fontSize: 18, fontWeight: "bold" }}
            >
              Infraestrutura
            </Typography>
            <List>
              <StyledListItem>
                <ListItemText primary="SQL Server" />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary="CI/CD" />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary="Git / TFS" />
              </StyledListItem>
            </List>
          </Box> */}
        </Box>
      </Container>
    </Box>
  );
};

export default Technologies;
