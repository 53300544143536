import { Container, Grid, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { CardMedia } from "@mui/material";

import React from "react";

const AutoM: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      id="products-container"
      sx={{
        marginTop: { xs: 5, md: 10 },
        marginBottom: { xs: 10, md: 20 },
      }}
    >
      <Container>
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontSize: { xs: 45, md: 65 },
              marginBottom: 2,
              color: theme.palette.secondary.main,
              marginRight: { xs: 1, md: 2 },
            }}
          >
            Auto
            <span style={{ fontWeight: "bold" }}>M</span>
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={{ marginBottom: 2 }}>
                o AutoM é um software que automatiza o processo de
                desconsolidação mercantil, otimizando o tempo de trabalho e
                reduzindo possíveis erros humanos.
              </Typography>
              <Typography sx={{ marginBottom: 2 }}>
                Além de preencher automáticamente 80% das informações contidas
                no processo, incluindo todos os containers, o AutoM ainda
                consegue gerar os comprovantes CE Master e CE House com apenas
                um clique.
              </Typography>
              <Typography sx={{ marginBottom: 2 }}>
                Também oferecemos um rico para acompanhamento, além de disparo
                de notificações para o você ficar por dentro do status de cada
                processo.
              </Typography>
              <Typography sx={{ marginBottom: 2 }}>
                Entre em contato com a nossa equipe e agende já o seu períiodo
                de teste completamente gratuito!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="video"
              controls
              src="./apresentacao-autom.mp4"
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AutoM;
