import { Container, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";

// import { Container } from './styles';

const About: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      id="about-container"
      sx={{
        marginTop: { xs: 5, md: 10 },
        marginBottom: { xs: 10, md: 20 },
      }}
    >
      <Container>
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontSize: { xs: 45, md: 65 },
              marginBottom: 2,
              color: theme.palette.secondary.main,
              marginRight: { xs: 1, md: 2 },
            }}
          >
            Sobre
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 45, md: 65 },
              fontWeight: "bold",
              marginBottom: 2,
              color: theme.palette.secondary.main,
            }}
          >
            Nós
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ marginBottom: 2 }}>
            Desenvolvemos softwares sob medida que vão de encontro com as
            particularidades e realidade de cada cliente, criando soluções
            inovadoras e altamente produtivas.
          </Typography>
          <Typography sx={{ marginBottom: 2 }}>
            A SS nasce com o objetivo de modernizar o cenário atual do
            desenvolvimento de softwares. Fórmulas padronizadas e engessadas dão
            vez a soluções cada vez mais personalizadas, respeitando as
            diversidades de cada cliente. Com 5 anos de experiência no mercado,
            a Solid Software já participou de diversos projetos em diversas
            áreas: bancária, mercante, ERP, aplicativos móveis,
            sites/plataformas digitais, entre outras.
          </Typography>
          <Typography sx={{ marginBottom: 2 }}>
            Nosso trabalho é inteiramente pautado pelos princípios de boas
            práticas na programação, os quais nos permitem desenvolver sistemas
            sólidos e eficientes. Seguindo sempre esses ideais, oferecemos um
            amplo leque de serviços aos nossos clientes, executados por
            especialistas dedicados a entregar os melhores resultados.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default About;
