import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

// import { Container } from './styles';

const Banner: React.FC = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          backgroundImage: "url(./banner.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "650px",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: "650px",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 40, md: 70 },
              color: "white",
              fontWeight: "bold",
            }}
          >
            SOLID
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 40, md: 70 },
              color: "white",
              fontWeight: 300,
              marginTop: { xs: -3, md: -5 },
            }}
          >
            SOFTWARE
          </Typography>
          <Typography
            sx={{ color: "white", fontSize: { xs: 25, md: 30 }, marginTop: 2 }}
          >
            Solidez para o seu negócio.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Banner;
