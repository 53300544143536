import {
  Divider,
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Toolbar,
  Button,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  drawerWidth: number;
  window?: any;
  handleDrawerToggle(): void;
  handleClick(event: any, id: string): void;
  mobileOpen: boolean;
}

const Drawer: React.FC<IProps> = (props: IProps) => {
  const { window, drawerWidth, mobileOpen, handleDrawerToggle, handleClick } =
    props;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <MUIDrawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button onClick={handleDrawerToggle} sx={{ minWidth: "40px" }}>
            <CloseIcon />
          </Button>
        </Toolbar>
        <Divider />
        <List>
          <ListItem
            button
            key="Sobre Nós"
            onClick={(event: any) => handleClick(event, "#about-container")}
          >
            <ListItemText primary="Sobre Nós" />
          </ListItem>
          <ListItem
            button
            key="Tecnologias"
            onClick={(event: any) =>
              handleClick(event, "#technologies-container")
            }
          >
            <ListItemText primary="Tecnologias" />
          </ListItem>
          <ListItem
            button
            key="Fale Conosco"
            onClick={(event: any) => handleClick(event, "#contact-container")}
          >
            <ListItemText primary="Fale Conosco" />
          </ListItem>
        </List>
      </MUIDrawer>
    </Box>
  );
};

export default Drawer;
